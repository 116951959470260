<template>
  <div class="main-content">
    <!-- 标题 -->
    <div class="title">
      <div class="star" v-if="title && required"><span>*</span></div>
      <div>{{ title }}{{ title != "" ? ":" : "" }}</div>
    </div>
    <!-- 上传按钮 -->
    <div v-if="isEdit" class="upload-btn">
      <el-upload
        action="#"
        multiple
        :show-file-list="false"
        :accept="limitType.map((val) => '.' + val).toString()"
        :http-request="() => {}"
        :on-change="uploadChange"
      >
        <el-button size="mini" type="primary" class="up-btn">
          <div class="up-btn-text">
            <i class="el-icon-upload"></i>
            <span>上传文件</span>
          </div>
        </el-button>
      </el-upload>
    </div>
    <!-- 提示文字 -->
    <div class="tips-text" v-if="isEdit">
      {{ tips }}
    </div>
    <!-- 文件展示 -->
    <div class="file-show" v-if="list.length">
      <div v-for="(file, index) in list" :key="index" class="file-show-item">
        <FileIcon
          :fileName="file.fileName || file.attachmentName"
          :suffix="file.fileType"
          fileNamePosition="right"
          :showDelet="false"
          style="margin-left: 0"
        />
        <el-button type="text" @click="preFile(file.url || file.attachmentUrl)">
          <span class="text-btn">查看</span>
        </el-button>
        <el-button type="text" @click="downloadAllAttachment(index)">
          <span class="text-btn">下载</span>
        </el-button>
        <i v-if="isEdit" class="el-icon-circle-close" @click="deletFile(index)">
        </i>
      </div>
    </div>
    <div class="tips-text" v-else-if="!isEdit">--</div>
  </div>
</template>

<script>
import emitter from "element-ui/src/mixins/emitter";
import _ from "lodash";
import { OSSDirectPass } from "@/api/oss.js";
import { downloadZIP } from "@/utils/index";
import FileIcon from "@/components/DragMultipleUpload/FileIcon.vue";
export default {
  name: "FormListUpload",
  mixins: [emitter],
  components: { FileIcon },
  model: {
    prop: "fileList",
    event: "change",
  },
  props: {
    required: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "未知",
    },
    tips: {
      type: String,
      default: "支持格式：.doc .docx .jpg .jpeg .png .pdf等类型",
    },
    limit: {
      type: Number,
      default: 100,
    },
    limitType: {
      type: Array,
      default: () => {
        //"doc", "docx", "jpg", "jpeg", "png", "pdf"
        return [];
      },
    },
    fileList: {
      //attachmentType:'',fileName:'',fileType:'',url:'
      type: [Array, String, undefined],
      default: () => {
        return [];
      },
    },
    attachmentType: {
      type: String,
      default: "",
    },
    isEdit: {
      type: Boolean,
      default: true,
    },
    limitSize: {
      type: Number,
      default: 100,
    },
  },
  data() {
    return {
      list: [],
      addNum: 0,
    };
  },
  watch: {
    fileList: {
      immediate: true,
      deep: true,
      handler(n) {
        if (n == "") {
          n = [];
        }
        this.list = _.cloneDeep(n);
      },
    },
  },
  methods: {
    handleExceed(files, fileList) {
      // console.log(files, fileList);
      // this.$message({
      //   message: `最多上传${this.limit}个文件`,
      //   type: "warning",
      // });
    },
    async uploadChange(file) {
      let suffix = file.name.split(".");
      suffix = suffix[suffix.length - 1] || "other";
      if (this.list.length >= this.limit) {
        this.$message({
          message: `最多上传${this.limit}个文件`,
          type: "warning",
        });
        return;
      }
      if (!this.limitType.includes(suffix) && this.limitType.length) {
        this.$message({
          message: `不支持该文件类型上传！`,
          type: "warning",
        });
        return;
      }
      let fileSize = file.size / 1024 / 1024;
      if (fileSize > this.limitSize) {
        this.$message({
          message: `上传的文件大于${this.limitSize}MB`,
          type: "warning",
        });
        return;
      };
      this.addNum += 1;
      const url = await OSSDirectPass(file);
      this.addNum -= 1;
      if (url != "") {
        this.list.push({
          attachmentType: this.attachmentType,
          fileName: file.name,
          attachmentName: file.name,
          fileType: suffix,
          attachmentUrl: url,
          url,
        });
        this.$emit("change", this.list.length ? this.list : []);
        this.dispatch("ElFormItem", "el.form.change", this.list);
      };
      if (this.addNum === 0) {
        this.$emit("allSuccess")
      }
    },
    deletFile(index) {
      let delData = this.list.splice(index, 1);
      this.$emit("change", this.list.length ? this.list : []);
      this.dispatch("ElFormItem", "el.form.change", this.list.length);
      this.$emit('delItem',delData[0])
    },
    downloadAllAttachment(index) {
      const loading = this.$loading({
        lock: true,
        text: "下载中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let arr = [];
      arr = [
        {
          name: this.list[index].fileName
            ? `${this.list[index].fileName}.${this.list[index].fileType}`
            : `${this.list[index].attachmentName}.${this.list[index].fileType}`,
          url: this.list[index].url || this.list[index].attachmentUrl,
        },
      ];
      downloadZIP(arr, "附件")
        .then((res) => {
          loading.close();
        })
        .catch((err) => {
          loading.close();
        });
    },
    preFile(url) {
      window.open(url, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.main-content {
  display: column !important;
  //margin: 16px 0;
  width: 100%;
  .title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: 400;
    color: #333333;
    font-size: 14px;
    .star {
      color: #c94242ff;
    }
  }
  .file-show {
    display: flex;
    flex-wrap: wrap;
    .file-show-item {
      display: flex;
      align-items: center;
      min-width: 320px;
      .text-btn {
        text-decoration: underline;
      }
      .el-icon-circle-close {
        width: 18px;
        height: 18px;
        margin-left: 21px;
      }
    }
  }
  .tips-text {
    font-weight: 400;
    color: #cccccc;
    font-size: 12px;
    margin-top: 6px;
  }
  .upload-btn {
    margin-top: 12px;
    .up-btn {
      background: #4278c9;
      border: none;
      width: 104px;
      height: 32px;
      .up-btn-text {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .el-icon-upload {
        font-size: 18px;
        margin-right: 6px;
      }
    }
  }
}
</style>
