var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-col-100"},[_c('el-form',{ref:"dataForm",staticStyle:{"height":"90%","overflow":"auto"},attrs:{"model":_vm.dataForm,"rules":_vm.rules}},[_c('AssociationScroll',[_c('AssociationScrollView',{attrs:{"title":"个人信息","name":"0"}},[_c('TextInputContainer',[_c('el-form-item',{attrs:{"prop":"name"}},[_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
                required: true,
                label: '姓名：',
                type: 'input',
              }},model:{value:(_vm.dataForm.name),callback:function ($$v) {_vm.$set(_vm.dataForm, "name", $$v)},expression:"dataForm.name"}})],1),_c('el-form-item',{attrs:{"prop":"phone"}},[_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
                required: true,
                label: '手机号：',
                type: 'input',
              }},model:{value:(_vm.dataForm.phone),callback:function ($$v) {_vm.$set(_vm.dataForm, "phone", $$v)},expression:"dataForm.phone"}})],1),_c('el-form-item',{attrs:{"prop":"cardNo"}},[_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
                required: true,
                label: '身份证号：',
                type: 'input',
              }},model:{value:(_vm.dataForm.cardNo),callback:function ($$v) {_vm.$set(_vm.dataForm, "cardNo", $$v)},expression:"dataForm.cardNo"}})],1),_c('el-form-item',[_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
                label: '性别：',
                type: 'radio',
                option: _vm.dictList.sexDict,
              }},model:{value:(_vm.dataForm.sex),callback:function ($$v) {_vm.$set(_vm.dataForm, "sex", $$v)},expression:"dataForm.sex"}})],1),_c('el-form-item',[_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
                label: '年龄：',
                type: 'inputNumber',
              }},model:{value:(_vm.dataForm.age),callback:function ($$v) {_vm.$set(_vm.dataForm, "age", $$v)},expression:"dataForm.age"}})],1),_c('el-form-item',[_c('TextInput',{attrs:{"isText":!_vm.isEdit,"form":_vm.dataForm,"config":{
                label: '所在城市：',
                regionKeys: ['province', 'city'],
                modelTextKey: 'areaDist',
                type: 'distpicker',
              }},on:{"returnVal":_vm.handleDistpicker},model:{value:(_vm.dataForm.areaDist),callback:function ($$v) {_vm.$set(_vm.dataForm, "areaDist", $$v)},expression:"dataForm.areaDist"}})],1),_c('el-form-item',[_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
                label: '工作单位：',
                type: 'input',
              }},model:{value:(_vm.dataForm.workUnit),callback:function ($$v) {_vm.$set(_vm.dataForm, "workUnit", $$v)},expression:"dataForm.workUnit"}})],1),_c('el-form-item',[_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
                label: '所学专业：',
                type: 'select',
                option: _vm.dictList.Major,
                modelKey: 'majorValue',
                modelTextKey: 'major',
              },"optionConfig":{
                value: 'dictKey',
                label: 'dictValue',
              },"form":_vm.dataForm},on:{"returnVal":_vm.getKeyToValue},model:{value:(_vm.dataForm.majorValue),callback:function ($$v) {_vm.$set(_vm.dataForm, "majorValue", $$v)},expression:"dataForm.majorValue"}})],1),_c('el-form-item',[_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
                label: '学历：',
                type: 'select',
                option: _vm.dictList.EducationalBackground,
                modelKey: 'degreeValue',
                modelTextKey: 'degree',
              },"optionConfig":{
                value: 'dictKey',
                label: 'dictValue',
              },"form":_vm.dataForm},on:{"returnVal":_vm.getKeyToValue},model:{value:(_vm.dataForm.degree),callback:function ($$v) {_vm.$set(_vm.dataForm, "degree", $$v)},expression:"dataForm.degree"}})],1),_c('el-form-item',[_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
                label: '毕业院校：',
                type: 'input',
              }},model:{value:(_vm.dataForm.graduateSchool),callback:function ($$v) {_vm.$set(_vm.dataForm, "graduateSchool", $$v)},expression:"dataForm.graduateSchool"}})],1),_c('el-form-item',[_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
                label: '职称：',
                type: 'select',
                option: _vm.dictList.JobTitle,
                modelKey: 'jobTitleValue',
                modelTextKey: 'jobTitle',
              },"optionConfig":{
                value: 'dictKey',
                label: 'dictValue',
              },"form":_vm.dataForm},on:{"returnVal":_vm.getKeyToValue},model:{value:(_vm.dataForm.jobTitleValue),callback:function ($$v) {_vm.$set(_vm.dataForm, "jobTitleValue", $$v)},expression:"dataForm.jobTitleValue"}})],1),_c('el-form-item',[_c('TextInput',{attrs:{"isText":!_vm.isEdit,"form":_vm.dataForm,"config":{
                label: '服务区域：',
                regionKeys: ['province', 'city'],
                modelTextKey: 'serverAreaDist',
                type: 'distpicker',
                multiple: true,
              }},on:{"returnVal":_vm.handleServerAreaDist},model:{value:(_vm.dataForm.serverAreaDist),callback:function ($$v) {_vm.$set(_vm.dataForm, "serverAreaDist", $$v)},expression:"dataForm.serverAreaDist"}})],1),_c('el-form-item',[_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
                label: '工作/擅长行业：',
                type: 'select',
                option: _vm.dictList.WorkProfession,
                modelKey: 'workProfessionValue',
                modelTextKey: 'workProfession',
              },"optionConfig":{
                value: 'dictKey',
                label: 'dictValue',
              },"form":_vm.dataForm},on:{"returnVal":_vm.getKeyToValue},model:{value:(_vm.dataForm.workProfessionValue),callback:function ($$v) {_vm.$set(_vm.dataForm, "workProfessionValue", $$v)},expression:"dataForm.workProfessionValue"}})],1),_c('el-form-item',[_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
                label: '擅长领域：',
                type: 'input',
              }},model:{value:(_vm.dataForm.areasOfExpertise),callback:function ($$v) {_vm.$set(_vm.dataForm, "areasOfExpertise", $$v)},expression:"dataForm.areasOfExpertise"}})],1),_c('el-form-item',[_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
                label: '擅长工作内容：',
                type: 'input',
              }},model:{value:(_vm.dataForm.workContent),callback:function ($$v) {_vm.$set(_vm.dataForm, "workContent", $$v)},expression:"dataForm.workContent"}})],1),_c('el-form-item',[_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
                label: '服务费用标准：',
                type: 'input',
                isNum: true,
              }},model:{value:(_vm.dataForm.standardCharge),callback:function ($$v) {_vm.$set(_vm.dataForm, "standardCharge", $$v)},expression:"dataForm.standardCharge"}})],1)],1),_c('el-form-item',[_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
              label: '工作经历：',
              type: 'textarea',
              width: '100%',
            }},model:{value:(_vm.dataForm.jobExperienceDesc),callback:function ($$v) {_vm.$set(_vm.dataForm, "jobExperienceDesc", $$v)},expression:"dataForm.jobExperienceDesc"}})],1)],1),_c('AssociationScrollView',{attrs:{"title":"账号信息","name":"1"}},[_c('TextInputContainer',[_c('el-form-item',[_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
                disabled: !!_vm.id,
                label: '手机号(登录账号):',
                type: 'input',
              }},model:{value:(_vm.dataForm.userName),callback:function ($$v) {_vm.$set(_vm.dataForm, "userName", $$v)},expression:"dataForm.userName"}},[(_vm.isEdit && _vm.id)?_c('div',{staticClass:"reset-pwd",attrs:{"slot":"label-top"},on:{"click":_vm.resetPwd},slot:"label-top"},[_c('img',{staticStyle:{"width":"16px","height":"16px","margin-right":"4px"},attrs:{"src":require("@/assets/images/safeDuty/reset-ex-pwd.png")}}),_c('span',[_vm._v("重置密码")])]):_vm._e()])],1),_c('el-form-item',[_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
                label: '账号状态:',
                type: 'radio',
                option: _vm.dictList.isEnable,
              }},model:{value:(_vm.dataForm.userEnable),callback:function ($$v) {_vm.$set(_vm.dataForm, "userEnable", $$v)},expression:"dataForm.userEnable"}})],1)],1)],1),_c('AssociationScrollView',{attrs:{"title":"专家资质","name":"2"}},[_c('FormListUpload',{attrs:{"required":"","isEdit":_vm.isEdit,"title":"","limit":10,"limitSize":5,"limitType":['jpg', 'jpeg', 'png', 'mp4'],"tips":"支持格式：.jpg .jpeg .png .pdf  ，单个文件不能超过5MB，最多上传10个文件","attachmentType":"sd_expert_file"},on:{"change":function($event){return _vm.$refs.dataForm.validateField('attachmentList')}},model:{value:(_vm.dataForm.attachmentList),callback:function ($$v) {_vm.$set(_vm.dataForm, "attachmentList", $$v)},expression:"dataForm.attachmentList"}}),_c('el-form-item',{attrs:{"prop":"attachmentList"}})],1),(!_vm.isEdit)?_c('div',[_c('AssociationScrollView',{attrs:{"title":"服务记录","name":"3"}},[_c('TextInputContainer',[_c('el-form-item',[_c('TextInput',{attrs:{"isText":"","config":{
                  label: '服务次数：',
                  type: 'input',
                }},model:{value:(_vm.dataForm.serverCount),callback:function ($$v) {_vm.$set(_vm.dataForm, "serverCount", $$v)},expression:"dataForm.serverCount"}})],1),_c('el-form-item',[_c('TextInput',{attrs:{"isText":"","config":{
                  label: '服务总金额：',
                  type: 'input',
                }},model:{value:(_vm.dataForm.serverAmount),callback:function ($$v) {_vm.$set(_vm.dataForm, "serverAmount", $$v)},expression:"dataForm.serverAmount"}})],1)],1)],1)],1):_vm._e()],1)],1),_c('div',{staticClass:"sd-bottom-btn"},[_c('el-button',{on:{"click":_vm.back}},[_vm._v("返回")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.submit}},[_vm._v("保存")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }