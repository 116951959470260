// 性别字典
export const sexDict = [
  {
    value: true,
    label: "男",
  },
  {
    value: false,
    label: "女",
  },
];

//启用字典
export const isEnable = [
  {
    value: true,
    label: "启用",
  },
  {
    value: false,
    label: "禁用",
  },
];
