<template>
  <div v-if="!disShow" style="position: relative">
    <!-- 编辑模式 -->
    <div
      :class="[labelPosition == 'left' ? 'label-left' : 'ti-custom-class']"
      :style="{
        margin: defaultConfig.label ? defaultConfig.labelPadding : '0',
        justifyContent: defaultConfig.layout,
      }"
      v-if="!textShow && !isText"
    >
      <!-- 标题 -->
      <div
        v-if="defaultConfig.label"
        :class="['label', labelPosition == 'top' ? 'label-top' : '']"
        :style="{
          width: labelPosition == 'left' ? 'auto' : defaultConfig.width,
          marginRight: labelPosition == 'left' ? '8px' : '',
          marginBottom: labelPosition == 'top' ? '8px' : '',
        }"
      >
        <div style="width: fit-content">
          <span v-if="defaultConfig.required" style="color: #c94242">*</span
          >{{ defaultConfig.label }}
        </div>
        <slot v-if="labelPosition == 'top'" name="label-top"></slot>
      </div>
      <div style="flex: 1; text-align: left">
        <template v-if="defaultConfig.type == 'slot'">
          <div :style="{ width: defaultConfig.width || 'fit-content' }">
            <slot></slot>
          </div>
        </template>
        <template v-if="defaultConfig.type == 'input'">
          <el-input
            :style="{ width: defaultConfig.width || 'fit-content' }"
            v-model="innerValue"
            :placeholder="defaultConfig.placeholder || '请输入'"
            :disabled="defaultConfig.disabled"
          >
            <template slot="append">
              <slot name="inputAppend"></slot>
            </template>
          </el-input>
        </template>
        <template v-if="defaultConfig.type == 'searchInput'">
          <el-autocomplete
            :style="{
              width: defaultConfig.width || 'fit-content',
              flex: defaultConfig.width ? '' : '1',
            }"
            v-model="innerValue"
            :fetch-suggestions="querySearchAsync"
            @select="handleSelectQuerySearchAsync"
          >
          </el-autocomplete>
        </template>
        <template v-if="defaultConfig.type == 'textarea'">
          <el-input
            type="textarea"
            :style="{
              width: defaultConfig.width || 'fit-content',
              flex: defaultConfig.width ? '' : '1',
            }"
            v-model="innerValue"
            :placeholder="defaultConfig.placeholder || '请输入'"
            :disabled="defaultConfig.disabled"
          ></el-input>
        </template>
        <template v-if="defaultConfig.type == 'select'">
          <el-select
            clearable
            filterable
            :disabled="defaultConfig.disabled"
            :style="{ width: defaultConfig.width || 'fit-content' }"
            v-model="innerValue"
            :placeholder="defaultConfig.placeholder || '请选择'"
          >
            <el-option
              v-for="(item, index) in defaultConfig.option"
              :label="item[optionConfig.label]"
              :value="item[optionConfig.value]"
              :key="index"
            ></el-option>
          </el-select>
        </template>
        <template v-if="defaultConfig.type == 'radio'">
          <div :style="{ width: defaultConfig.width || 'fit-content' }">
            <el-radio-group v-model="innerValue">
              <el-radio
                :disabled="defaultConfig.disabled"
                v-for="(item, index) in defaultConfig.option"
                :label="item[optionConfig.value]"
                :key="index"
                >{{ item[optionConfig.label] }}</el-radio
              >
            </el-radio-group>
          </div>
        </template>
        <template v-if="defaultConfig.type == 'inputNumber'">
          <el-input-number
            :disabled="defaultConfig.disabled"
            :style="{ width: defaultConfig.width || 'fit-content' }"
            v-model="innerValue"
            :min="0"
          >
          </el-input-number>
        </template>
        <template v-if="defaultConfig.type == 'datePicker'">
          <el-date-picker
            :disabled="defaultConfig.disabled"
            :style="{ width: defaultConfig.width || 'fit-content' }"
            v-model="innerValue"
            type="date"
            placeholder="选择日期"
          >
          </el-date-picker>
        </template>
        <template v-if="defaultConfig.type == 'daterange'">
          <el-date-picker
            :disabled="defaultConfig.disabled"
            :style="{ width: defaultConfig.width || 'fit-content' }"
            v-model="innerValue"
            type="daterange"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </template>
        <template v-if="defaultConfig.type == 'location'">
          <el-input
            :disabled="defaultConfig.disabled"
            :style="{ width: defaultConfig.width || 'fit-content' }"
            v-model="innerValue"
            :placeholder="defaultConfig.placeholder"
            ><template v-if="!defaultConfig.disabled" slot="append"
              ><img
                src="@/assets/images/policy/policyPosition.png"
                @click="isPositionDialog = true"
                style="width: 20px; height: 20px" /></template
          ></el-input>
          <positionDialog
            v-model="isPositionDialog"
            type="addressObj"
            :addressValue="addressValue"
            @submit="handleAddressVal"
          ></positionDialog>
        </template>
        <template v-if="defaultConfig.type == 'distpicker'">
          <el-cascader
            v-if="defaultConfig.multiple"
            collapse-tags
            :style="{ width: defaultConfig.width || 'fit-content' }"
            :options="areaSelectData"
            :change-on-select="true"
            :clearable="true"
            :filterable="true"
            :props="{
              multiple: defaultConfig.multiple,
              checkStrictly: true,
            }"
            v-model="innerValue"
            @change="handleRegionChange"
            @visible-change="visibleChangeCascader"
          />
          <el-cascader
            v-else
            :style="{ width: defaultConfig.width || 'fit-content' }"
            :options="areaSelectData"
            :change-on-select="true"
            :clearable="true"
            :filterable="true"
            :props="{
              checkStrictly: true,
            }"
            v-model="innerValue"
            @change="handleRegionChange"
            @visible-change="visibleChangeCascader"
          />
        </template>
      </div>
    </div>
    <!-- 文字显示模式 -->
    <div
      class="label"
      :style="{
        display: 'flex',
        marginTop: defaultConfig.labelPadding,
      }"
      v-if="textShow || isText"
    >
      <!-- 标题 -->
      <div style="display: flex; width: fit-content">
        <span v-if="defaultConfig.required" style="color: #c94242">*</span>
        <div>{{ defaultConfig.label }}</div>
      </div>
      <div v-if="defaultConfig.type == 'textarea'" class="text-show text-show2">
        {{ innerText || "---" }}
      </div>
      <div style="width: fit-content" v-else class="text-show">
        <span v-if="defaultConfig.type == 'slot'">
          <slot></slot>
        </span>
        <span v-else>{{ innerText || "---" }}</span>
        <template v-if="$slots.inputAppend">
          {{ $slots.inputAppend[0].children[0].text }}
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { provinceAndCityData, codeToText } from "element-china-area-data";
import positionDialog from "@/views/policyManagement/components/positionDialog.vue";
import dayjs from "dayjs";
import { areaTextToCode } from "./area.js";
export default {
  name: "TextInput",
  model: {
    prop: "modeValue",
    event: "update:modelValue",
  },
  components: { positionDialog },
  props: {
    optionConfig: {
      type: Object,
      default: () => {
        return {
          value: "value",
          label: "label",
        };
      },
    },
    config: {
      type: Object,
      default: () => {
        return {};
      },
    },
    modeValue: {
      type: [String, Number, Array, Object, Boolean],
      default: () => {
        return "";
      },
    },
    isText: {
      type: Boolean,
      default: false,
    },
    disShow: {
      type: Boolean,
      default: false,
    },
    labelPosition: {
      type: String,
      default: "top",
    },
    querySearchAsync: {
      type: Function,
      default: (queryString, cb) => {
        cb([]);
        return;
      },
    },
    form: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  watch: {
    config: {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        this.defaultConfig = { ...this.defaultConfig, ...newValue };
      },
    },
  },
  computed: {
    innerText: {
      get() {
        if (
          this.defaultConfig.type == "slot" ||
          this.defaultConfig.type == "input" ||
          this.defaultConfig.type == "inputNumber" ||
          this.defaultConfig.type == "datePicker" ||
          this.defaultConfig.type == "textarea" ||
          this.defaultConfig.type == "searchInput" ||
          this.defaultConfig.type == "location"
        ) {
          return this.modeValue;
        } else if (
          this.defaultConfig.type == "radio" ||
          this.defaultConfig.type == "select"
        ) {
          if (
            this.form &&
            this.defaultConfig.modelTextKey &&
            this.form[this.defaultConfig.modelTextKey]
          ) {
            return this.form[this.defaultConfig.modelTextKey];
          } else {
            return this.findText(this.innerValue);
          }
        } else if (this.defaultConfig.type == "daterange") {
          //let dates = this.modeValue;
          return `${this.form[this.defaultConfig.daterangeKeys[0]]}~${
            this.form[this.defaultConfig.daterangeKeys[1]]
          }`;
        } else if (this.defaultConfig.type == "distpicker") {
          if (this.defaultConfig.multiple) {
            let resText = [];
            this.modeValue.forEach((af) => {
              resText.push(
                `${af[this.defaultConfig.regionKeys[0]]}/${
                  af[this.defaultConfig.regionKeys[1]] || "---"
                }`
              );
            });
            return resText.join(", ");
          } else {
            return `${this.form[this.defaultConfig.regionKeys[0]]}/${
              this.form[this.defaultConfig.regionKeys[1]]
            }`;
          }
        }
      },
      set(val) {},
    },
    innerValue: {
      get() {
        if (this.defaultConfig.type == "distpicker") {
          if (this.defaultConfig.multiple) {
            let res = [];
            this.form[this.defaultConfig.modelTextKey].forEach((af) => {
              res.push(
                areaTextToCode([
                  af[this.defaultConfig.regionKeys[0]],
                  af[this.defaultConfig.regionKeys[1]],
                ])
              );
            });
            return res;
          } else {
            let res = areaTextToCode([
              this.form[this.defaultConfig.regionKeys[0]],
              this.form[this.defaultConfig.regionKeys[1]],
            ]);
            return res;
          }
        } else if (this.defaultConfig.type == "daterange") {
          return [
            this.form[this.defaultConfig.daterangeKeys[0]],
            this.form[this.defaultConfig.daterangeKeys[1]],
          ];
        }
        return this.modeValue;
      },
      set(val) {
        let returnVal = val;
        if (
          this.defaultConfig.type == "input" ||
          this.defaultConfig.type == "inputNumber" ||
          this.defaultConfig.type == "textarea" ||
          this.defaultConfig.type == "searchInput"
        ) {
          if (this.defaultConfig.isNum && this.defaultConfig.type == "input") {
            // let RegExp = /^[+-]?(0|([1-9]\d*))(\.\d+)?$/g;
            let RegExp = /[^\d.]/g;
            returnVal = returnVal.replace(RegExp, "");
          }

          this.$emit("update:modelValue", returnVal);
          if (this.defaultConfig.modelTextKey) {
            this.$emit("returnVal", {
              data: returnVal,
              type: this.defaultConfig.modelTextKey,
              comType: this.defaultConfig.type,
            });
          }
        } else if (this.defaultConfig.type == "datePicker") {
          this.$emit(
            "update:modelValue",
            returnVal
              ? dayjs(returnVal).format(this.defaultConfig.dateFormat)
              : ""
          );
        } else if (
          this.defaultConfig.type == "radio" ||
          this.defaultConfig.type == "select"
        ) {
          this.$emit("update:modelValue", returnVal);
          this.$emit("returnVal", {
            data: this.findText(returnVal, true),
            type: this.defaultConfig.modelTextKey,
            comType: this.defaultConfig.type,
          });
        } else if (this.defaultConfig.type == "daterange") {
          // FIXME 清空会触发两次
          let dates = [];
          returnVal?.forEach((date) => {
            dates.push(dayjs(date).format(this.defaultConfig.dateFormat));
          });
          returnVal = dates;
          if (this.defaultConfig.daterangeKeys.length > 0) {
            let deal = {};
            this.defaultConfig.daterangeKeys.forEach((key, index) => {
              if (this.defaultConfig.daterangeFormats[index]) {
                deal[key] = dayjs(returnVal[index]).format(
                  this.defaultConfig.daterangeFormats[index]
                );
              } else {
                deal[key] = returnVal[index];
              }
            });
            if (!returnVal.length) {
              this.defaultConfig.daterangeKeys.forEach((key, index) => {
                deal[key] = "";
              });
            }
            this.$emit("dataDeal", deal);
            this.$emit("returnVal", {
              data: {
                dates: deal,
                daterangeKeys: this.defaultConfig.daterangeKeys,
              },
              type: this.defaultConfig.modelTextKey,
              comType: this.defaultConfig.type,
            });
          }
          this.$emit("update:modelValue", returnVal);
        } else if (this.defaultConfig.type == "distpicker") {
          if (this.defaultConfig.multiple) {
            this.distpickerMultiple = returnVal;
          } else {
            this.$emit("update:modelValue", returnVal);
          }
        }
      },
    },
  },
  data() {
    return {
      isPositionDialog: false,
      text: "",
      //innerText: "",
      textShow: false,
      addressValue: "",
      distForm: {
        province: "",
        city: "",
        area: "",
      },
      distpickerMultiple: [],
      areaSelectData: provinceAndCityData,
      defaultConfig: {
        label: "",
        //排布
        layout: "flex-start",
        labelPadding: "24px 0 0 0",
        placeholder: "",
        value: "",
        disabled: false,
        readonly: false,
        clearable: false,
        maxlength: 0,
        minlength: 0,
        showWordLimit: false,
        autosize: false,
        autofocus: false,
        width: "290px",
        rows: 0,
        // input select datePicker radio inputNumber location textarea daterange distpicker searchInput
        type: "",
        daterangeKeys: [],
        daterangeParams: [],
        dateFormat: "YYYY-MM-DD 00:00:00",
        daterangeFormats: ["YYYY-MM-DD 00:00:00", "YYYY-MM-DD 23:59:59"],
        regionKeys: ["province ", "city"],
        //slect radio 对应key 的 value
        modelTextKey: "",
        // input textarea 用于判断是否是数字
        isNum: false,
        required: false,
        // 级联是否多选
        multiple: false,
        option: [
          // {
          //   label: "选项1",
          //   value: "1",
          // },
          // {
          //   label: "选项2",
          //   value: "2",
          // },
        ],
      },
    };
  },
  methods: {
    findText(value = "", reobj = false) {
      let obj = this.defaultConfig.option?.find((item) => {
        return item[this.optionConfig.value] == value;
      });
      if (reobj) {
        return obj || {};
      }
      if (obj) {
        return obj[this.optionConfig.label];
      } else {
        return this.innerValue;
      }
    },
    handleAddressVal(e) {
      if (this.defaultConfig.modelKey) {
        this.$emit("update:modelValue", e.completeAddress);
        this.$emit("getLocation", e);
      }
      this.isPositionDialog = false;
    },
    handleRegionChange(e = []) {
      // let res = null;
      if (this.defaultConfig.multiple) {
        this.distpickerMultiple = e;
      } else {
        let res = {};
        this.defaultConfig.regionKeys.forEach((key, index) => {
          res[key] = codeToText[e[index]];
        });
        this.$emit("returnVal", {
          data: res,
          type: this.defaultConfig.modelTextKey,
          comType: this.defaultConfig.type,
        });
      }
    },
    handleSelectQuerySearchAsync(e) {
      this.$emit("handleSelectQuerySearchAsync", e);
    },
    // 处理级联省市高度适应
    visibleChangeCascader(e) {
      if (e) {
        const cascader = document.getElementsByClassName("el-cascader-panel");
        for (let i = 0; i < cascader.length; i++) {
          cascader[i].style.height = "auto";
          cascader[i].style.maxHeight = "500px";
        }
      } else if (!e && this.defaultConfig.multiple) {
        this.$emit("update:modelValue", this.distpickerMultiple);
        let res = [];
        this.distpickerMultiple.forEach((af) => {
          let sobj = {};
          this.defaultConfig.regionKeys.forEach((key, index) => {
            sobj[key] = codeToText[af[index]];
          });
          res.push(sobj);
        });
        this.$emit("returnVal", {
          data: res,
          type: this.defaultConfig.modelTextKey,
          comType: this.defaultConfig.type,
        });
      }
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.text-show {
  width: 280px;
  word-wrap: break-word;
}
.text-show2 {
  flex: 1;
}
.label {
  font-weight: 400;
  font-size: 14px;
  color: #333333;
  display: flex;
  align-items: center;
}
.label-top {
  justify-content: space-between;
}
.label-left {
  text-align: end;
  display: flex;
}
</style>
