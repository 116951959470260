import { provinceAndCityData } from "element-china-area-data";
export function areaTextToCode(pAc = ["", ""]) {
  let res = [];
  for (let i = 0; i < provinceAndCityData.length; i++) {
    if (provinceAndCityData[i].label == pAc[0]) {
      let p = provinceAndCityData[i];
      res.push(p.value);
      if (pAc[1]) {
        for (let ci = 0; ci < p.children.length; ci++) {
          if (p.children[ci].label == pAc[1]) {
            let c = p.children;
            res.push(provinceAndCityData[i].children[ci].value);
            break;
          }
        }
      }
      break;
    }
  }
  return res;
}
