<template>
  <el-collapse-item :name="name">
    <template slot="title">
      <div class="view-title"
        @click="clickIntercept">
        <div class="view-title-left">
          <img src="@/assets/images/policy/title-left.png"
            alt
            srcset />
          <div>{{ title }}</div>
          <div data-nostop="true"
            class="deployment-box">
            {{ isDeployment ? '收起' : '展开' }}
            <i data-nostop="true"
              style="font-size: 16px;transition: .3s;"
              :class="[ isDeployment ? 'el-icon-arrow-up' : 'el-icon-arrow-down']"></i>
          </div>
          <slot name="head-left" />
        </div>
        <div class="view-title-right">
          <slot name="head-right" />
        </div>
      </div>
    </template>
    <slot></slot>
  </el-collapse-item>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '2222'
    },
    name: {
      type: String,
      default: '2222'
    }
  },
  data() {
    return {
      isDeployment: true,
    };
  },
  methods: {
    clickIntercept(e) {
      if (!e.target.dataset.nostop) {
        e.stopPropagation();
      } else {
        this.isDeployment = !this.isDeployment;
      }
    }
  }
}
</script>


<style lang="scss" scoped>
.el-collapse-box {
  height: 100%;
  .view-title {
    flex: 1;
    display: flex;
    justify-content: space-between;
    &-left {
      display: flex;
      align-items: center;
      height: 48px;
      font-size: 18px;
      font-weight: bold;
      color: #333333;
      img {
        width: 6px;
        height: 24px;
        margin-right: 8px;
      }
    }
    &-right {
      display: flex;
      align-items: center;
    }
    &-float {
      position: fixed;
      background-color: #ffffff;
      border-bottom: none;
      border-radius: 8px 8px 0 0;
      padding: 12px 24px 0;
      margin: 0;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.12);
      z-index: 3;
    }
  }
  .el-collapse {
    border: none;
    .el-collapse-item {
      margin-bottom: 10px;
      padding: 24px;
      border-radius: 8px;
      border: none;
      background-color: #fff;
      overflow: hidden;
      ::v-deep .el-collapse-item__arrow {
        display: none;
      }
      ::v-deep .el-collapse-item__header {
        border-bottom: 1px dashed #dedede;
        cursor: default;
      }
      ::v-deep .el-collapse-item__wrap {
        border: none;
      }
      ::v-deep .el-collapse-item__content {
        padding: 0;
      }
    }
  }
  .deployment-box {
    font-size: 14px;
    color: #666666;
    margin-left: 16px;
    cursor: pointer;
  }
  .deployment-box:hover {
    color: #4278c9;
  }
}
</style>