<template>
  <div class="flex-col-100">
    <el-form
      style="height: 90%; overflow: auto"
      :model="dataForm"
      :rules="rules"
      ref="dataForm"
    >
      <AssociationScroll>
        <AssociationScrollView title="个人信息" name="0">
          <TextInputContainer>
            <el-form-item prop="name">
              <TextInput
                :isText="!isEdit"
                :config="{
                  required: true,
                  label: '姓名：',
                  type: 'input',
                }"
                v-model="dataForm.name"
              />
            </el-form-item>
            <el-form-item prop="phone">
              <TextInput
                :isText="!isEdit"
                :config="{
                  required: true,
                  label: '手机号：',
                  type: 'input',
                }"
                v-model="dataForm.phone"
              />
            </el-form-item>
            <el-form-item prop="cardNo">
              <TextInput
                :isText="!isEdit"
                :config="{
                  required: true,
                  label: '身份证号：',
                  type: 'input',
                }"
                v-model="dataForm.cardNo"
              />
            </el-form-item>
            <el-form-item>
              <TextInput
                :isText="!isEdit"
                :config="{
                  label: '性别：',
                  type: 'radio',
                  option: dictList.sexDict,
                }"
                v-model="dataForm.sex"
              />
            </el-form-item>
            <el-form-item>
              <TextInput
                :isText="!isEdit"
                :config="{
                  label: '年龄：',
                  type: 'inputNumber',
                }"
                v-model="dataForm.age"
              />
            </el-form-item>
            <el-form-item>
              <TextInput
                :isText="!isEdit"
                :form="dataForm"
                :config="{
                  label: '所在城市：',
                  regionKeys: ['province', 'city'],
                  modelTextKey: 'areaDist',
                  type: 'distpicker',
                }"
                @returnVal="handleDistpicker"
                v-model="dataForm.areaDist"
              />
            </el-form-item>
            <el-form-item>
              <TextInput
                :isText="!isEdit"
                :config="{
                  label: '工作单位：',
                  type: 'input',
                }"
                v-model="dataForm.workUnit"
              />
            </el-form-item>
            <el-form-item>
              <TextInput
                :isText="!isEdit"
                :config="{
                  label: '所学专业：',
                  type: 'select',
                  option: dictList.Major,
                  modelKey: 'majorValue',
                  modelTextKey: 'major',
                }"
                :optionConfig="{
                  value: 'dictKey',
                  label: 'dictValue',
                }"
                :form="dataForm"
                @returnVal="getKeyToValue"
                v-model="dataForm.majorValue"
              />
            </el-form-item>
            <el-form-item>
              <TextInput
                :isText="!isEdit"
                :config="{
                  label: '学历：',
                  type: 'select',
                  option: dictList.EducationalBackground,
                  modelKey: 'degreeValue',
                  modelTextKey: 'degree',
                }"
                :optionConfig="{
                  value: 'dictKey',
                  label: 'dictValue',
                }"
                :form="dataForm"
                @returnVal="getKeyToValue"
                v-model="dataForm.degree"
              />
            </el-form-item>
            <el-form-item>
              <TextInput
                :isText="!isEdit"
                :config="{
                  label: '毕业院校：',
                  type: 'input',
                }"
                v-model="dataForm.graduateSchool"
              />
            </el-form-item>
            <el-form-item>
              <TextInput
                :isText="!isEdit"
                :config="{
                  label: '职称：',
                  type: 'select',
                  option: dictList.JobTitle,
                  modelKey: 'jobTitleValue',
                  modelTextKey: 'jobTitle',
                }"
                :optionConfig="{
                  value: 'dictKey',
                  label: 'dictValue',
                }"
                :form="dataForm"
                @returnVal="getKeyToValue"
                v-model="dataForm.jobTitleValue"
              />
            </el-form-item>
            <el-form-item>
              <TextInput
                :isText="!isEdit"
                :form="dataForm"
                :config="{
                  label: '服务区域：',
                  regionKeys: ['province', 'city'],
                  modelTextKey: 'serverAreaDist',
                  type: 'distpicker',
                  multiple: true,
                }"
                @returnVal="handleServerAreaDist"
                v-model="dataForm.serverAreaDist"
              />
            </el-form-item>
            <el-form-item>
              <TextInput
                :isText="!isEdit"
                :config="{
                  label: '工作/擅长行业：',
                  type: 'select',
                  option: dictList.WorkProfession,
                  modelKey: 'workProfessionValue',
                  modelTextKey: 'workProfession',
                }"
                :optionConfig="{
                  value: 'dictKey',
                  label: 'dictValue',
                }"
                :form="dataForm"
                @returnVal="getKeyToValue"
                v-model="dataForm.workProfessionValue"
              />
            </el-form-item>
            <el-form-item>
              <TextInput
                :isText="!isEdit"
                :config="{
                  label: '擅长领域：',
                  type: 'input',
                }"
                v-model="dataForm.areasOfExpertise"
              />
            </el-form-item>
            <el-form-item>
              <TextInput
                :isText="!isEdit"
                :config="{
                  label: '擅长工作内容：',
                  type: 'input',
                }"
                v-model="dataForm.workContent"
              />
            </el-form-item>
            <el-form-item>
              <TextInput
                :isText="!isEdit"
                :config="{
                  label: '服务费用标准：',
                  type: 'input',
                  isNum: true,
                }"
                v-model="dataForm.standardCharge"
              />
            </el-form-item>
          </TextInputContainer>
          <el-form-item>
            <TextInput
              :isText="!isEdit"
              :config="{
                label: '工作经历：',
                type: 'textarea',
                width: '100%',
              }"
              v-model="dataForm.jobExperienceDesc"
            />
          </el-form-item>
        </AssociationScrollView>
        <AssociationScrollView title="账号信息" name="1">
          <TextInputContainer>
            <el-form-item>
              <TextInput
                :isText="!isEdit"
                :config="{
                  disabled: !!id,
                  label: '手机号(登录账号):',
                  type: 'input',
                }"
                v-model="dataForm.userName"
              >
                <div
                  @click="resetPwd"
                  v-if="isEdit && id"
                  slot="label-top"
                  class="reset-pwd"
                >
                  <img
                    src="@/assets/images/safeDuty/reset-ex-pwd.png"
                    style="width: 16px; height: 16px; margin-right: 4px"
                  />
                  <span>重置密码</span>
                </div>
              </TextInput>
            </el-form-item>
            <el-form-item>
              <TextInput
                :isText="!isEdit"
                :config="{
                  label: '账号状态:',
                  type: 'radio',
                  option: dictList.isEnable,
                }"
                v-model="dataForm.userEnable"
              />
            </el-form-item>
          </TextInputContainer>
        </AssociationScrollView>
        <AssociationScrollView title="专家资质" name="2">
          <FormListUpload
            required
            :isEdit="isEdit"
            title=""
            :limit="10"
            :limitSize="5"
            :limitType="['jpg', 'jpeg', 'png', 'mp4']"
            tips="支持格式：.jpg .jpeg .png .pdf  ，单个文件不能超过5MB，最多上传10个文件"
            attachmentType="sd_expert_file"
            v-model="dataForm.attachmentList"
            @change="$refs.dataForm.validateField('attachmentList')"
          ></FormListUpload>
          <!-- 用于显示校验错误信息 -->
          <el-form-item prop="attachmentList"> </el-form-item>
        </AssociationScrollView>
        <div v-if="!isEdit">
          <AssociationScrollView title="服务记录" name="3">
            <TextInputContainer>
              <el-form-item>
                <TextInput
                  isText
                  :config="{
                    label: '服务次数：',
                    type: 'input',
                  }"
                  v-model="dataForm.serverCount"
                />
              </el-form-item>
              <el-form-item>
                <TextInput
                  isText
                  :config="{
                    label: '服务总金额：',
                    type: 'input',
                  }"
                  v-model="dataForm.serverAmount"
                />
              </el-form-item>
            </TextInputContainer>
          </AssociationScrollView>
        </div>
      </AssociationScroll>
    </el-form>
    <div class="sd-bottom-btn">
      <el-button @click="back">返回</el-button>
      <el-button type="primary" @click="submit">保存</el-button>
    </div>
  </div>
</template>

<script>
import AssociationScroll from "@/components/AssociationScroll/AssociationScroll.vue";
import AssociationScrollView from "@/components/AssociationScroll/AssociationScrollView.vue";
import FormListUpload from "@/components/FormListUpload/index.vue";
import TextInput from "@/components/SimpleTable/TextInput.vue";
import TextInputContainer from "@/components/SimpleTable/TextInputContainer.vue";
import { dictionaryBatch } from "@/api/policy";
import { sexDict, isEnable } from "@/const/index.js";
import { addOrEditExpert, expertPwdReset } from "@/api/safeDuty.js";
import { verifyArryEmpty } from "@/components/SimpleTable/verify.js";
export default {
  components: {
    AssociationScroll,
    AssociationScrollView,
    FormListUpload,
    TextInput,
    TextInputContainer,
  },
  props: {
    id: {
      type: [String, Number],
      default: "",
    },
    detailData: {
      type: Object,
      default: () => {
        return null;
      },
    },
    isEdit: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    detailData(n) {
      if (n) {
        this.dataForm = { ...this.dataForm, ...n };
        this.dataForm.serverAreaDist = JSON.parse(
          this.dataForm.serviceLocation
        );
        this.$forceUpdate();
      }
    },
  },
  data() {
    return {
      dictList: {
        //是否启用
        isEnable,
        sexDict,
      },
      dataForm: {
        serverAreaDist: [],
        areaDist: [],
        age: "",
        areasOfExpertise: "",
        areasOfExpertiseValue: "",
        attachmentList: [
          // {
          //   attachmentName: "",
          //   attachmentUrl: "",
          //   fileType: "",
          // },
        ],
        cardNo: "",
        city: "",
        degree: "",
        degreeValue: "",
        graduateSchool: "",
        jobExperienceDesc: "",
        jobTitle: "",
        jobTitleValue: "",
        major: "",
        name: "",
        phone: "",
        province: "",
        serialNo: "",
        serverAmount: "",
        serverCount: "",
        serviceLocation: "",
        sex: true,
        standardCharge: "",
        userEnable: true,
        userName: "",
        workContent: "",
        workContentValue: "",
        workUnit: "",
      },
      rules: {
        attachmentList: [
          {
            validator: verifyArryEmpty,
            message: "请添专家资质材料",
            trigger: "blur",
          },
        ],
        name: [
          {
            required: true,
            message: "请输入姓名",
            trigger: "change",
          },
        ],
        phone: [
          {
            required: true,
            message: "请输入手机号码",
            trigger: "change",
          },
        ],
        cardNo: [
          {
            required: true,
            message: "请输入身份证号码",
            trigger: "change",
          },
        ],
      },
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.getDictionarySd();
    },
    getDictionarySd() {
      let dictKeys = [
        "JobContentGood",
        "AreaOfExpertise",
        "WorkProfession",
        "JobTitle",
        "EducationalBackground",
        "Major",
      ];
      dictionaryBatch({
        codes: dictKeys.join(","),
      }).then((res) => {
        if (res && res.data) {
          dictKeys.forEach((key) => {
            this.$set(this.dictList, key, res.data[key] || []);
          });
        }
      });
    },
    getKeyToValue(res) {
      this.dataForm[res.type] = res.data.dictValue;
    },
    handleDistpicker(res) {
      Object.keys(res.data).forEach((key) => {
        this.dataForm[key] = res.data[key];
      });
    },
    handleServerAreaDist(res) {
      this.dataForm.serverAreaDist = res.data;
      this.dataForm.serviceLocation = JSON.stringify(res.data);
    },
    submit() {
      this.$refs.dataForm.validate((valid) => {
        if (valid) {
          addOrEditExpert(this.dataForm).then((res) => {
            this.$message({
              type: "success",
              message: "成功!",
            });
            this.back();
          });
        }
      });
    },
    back() {
      this.$router.go(-1);
      // this.$router.replace({ path: this.$route.path });
    },
    resetPwd() {
      expertPwdReset({ bladeUserId: this.dataForm.bladeUserId }).then((res) => {
        this.$message({
          type: "success",
          message: "重置密码成功!",
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .ti-custom-class {
  margin-top: 10px !important;
  margin-bottom: unset !important;
}
.flex-col-100 {
  height: 100%;
  // display: flex;
  // flex-direction: column;
  // justify-content: space-between;
}
.sd-bottom-btn {
  width: 100%;
  //height: 100px;
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  background: #ffffff;
  box-shadow: 0px -3px 6px 1px rgba(0, 0, 0, 0.16);
  border-radius: 8px 8px 8px 8px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 24px 24px;
  z-index: 99;
}
.reset-pwd {
  font-weight: 400;
  font-size: 14px;
  color: #0080ff;
  display: flex;
  align-items: center;
  cursor: pointer;
}
</style>
