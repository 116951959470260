<template>
  <div class="folding-tab">
    <div class="el-collapse-box" ref="collapse-Scroll-box">
      <el-collapse v-model="activeName" ref="collapse">
        <slot></slot>
      </el-collapse>
    </div>
    <div class="right-tap" v-if="showTabs">
      <el-tabs
        tab-position="right"
        v-model="tabValue"
        @tab-click="handleClickAnchor"
      >
        <el-tab-pane
          :label="item"
          :name="index + ''"
          v-for="(item, index) in tabsList"
          :key="index"
        ></el-tab-pane>
      </el-tabs>
      <div class="right-down">
        <slot name="tabs-down"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showTabs: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      tabsList: [],
      tabValue: "0",
      activeName: [],
    };
  },
  created() {
    this.$nextTick(() => {
      let activeName = [];
      // this.tabsList = this.$slots.default.map((val, ind) => {
      //   activeName.push(ind + '');
      //   return val.elm.children[0].children[0].children[0].children[0].children[1].innerText;
      // });
      this.tabsList = this.$refs.collapse.$children.map((val, ind) => {
        activeName.push(val.name);
        return val.title;
      });
      this.activeName = activeName;
      if (!this.showTabs) return;
      this.$refs["collapse-Scroll-box"].addEventListener(
        "scroll",
        this.handleScroll,
        true
      );
    });
  },
  destroyed() {
    if (!this.showTabs) return;
    this.$refs["collapse-Scroll-box"]
      ? this.$refs["collapse-Scroll-box"].removeEventListener(
          "scroll",
          this.handleScroll
        )
      : "";
  },
  deactivated() {
    if (!this.showTabs) return;
    this.$refs["collapse-Scroll-box"]
      ? this.$refs["collapse-Scroll-box"].removeEventListener(
          "scroll",
          this.handleScroll
        )
      : "";
  },
  methods: {
    handleClickAnchor(v) {
      if (!this.showTabs) return;
      // this.$refs.collapse.$children.find(item => {
      //    console.log(item.name, v.name);
      //   if (item.name == v.name) {
      //     console.log(item, v);
      //   }
      //   return item.name == v.name;
      // }).$vnode.elm.scrollIntoView();
      this.$refs.collapse.$children[v.name].$el.scrollIntoView();
      //this.$slots.default[v.index].elm.scrollIntoView();
    },
    handleScroll() {
      if (!this.showTabs) return;
      const winHeight =
        this.$refs["collapse-Scroll-box"].scrollTop ||
        document.documentElement.scrollTop;
      // const index = this.$slots.default.findIndex(val => val.elm.offsetTop > winHeight);
      const index = this.$refs.collapse.$children.findIndex(
        (val) => val.$vnode.elm.offsetTop > winHeight
      );
      // this.tabValue = (index == -1 ? (this.$slots.default.length - 1) : index) + '';
      // this.tabValue = (index == -1 ? (this.$refs.collapse.$children[this.$refs.collapse.$children.length - 1].name) : this.$refs.collapse.$children[index].name);
      this.tabValue =
        (index == -1 ? this.$refs.collapse.$children.length - 1 : index) + "";
    },
  },
};
</script>

<style lang="scss" scoped>
.folding-tab {
  display: flex;
  width: 100%;
  height: 100%;
  .el-collapse-box {
    flex: 1;
    height: 100%;
    overflow: auto;
  }
  .view-title {
    flex: 1;
  }
  .el-collapse {
    border: none;
    .el-collapse-item {
      margin-bottom: 10px;
      padding: 24px;
      border-radius: 8px;
      border: none;
      background-color: #fff;
      overflow: hidden;
      ::v-deep .el-collapse-item__arrow {
        display: none;
      }
    }
  }
  ::v-deep .el-tabs__nav {
    .el-tabs__active-bar {
      width: 6px;
      border-radius: 3px;
      background-color: #4278c9;
    }
    .el-tabs__item {
      font-size: 14px;
      .is-active {
        color: #4278c9;
      }
    }
  }
  ::v-deep .el-tabs__header {
    width: 180px;
    height: auto;
    .el-tabs__nav-wrap::after {
      width: 6px;
      border-radius: 3px;
      background-color: #e1e1e1;
    }
  }
  .right-tap {
    display: flex;
    flex-direction: column;
    position: sticky;
    top: 0;
    .right-down {
      width: 180px;
      flex: 1;
      overflow: hidden;
    }
  }
}
</style>
