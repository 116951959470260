import { render, staticRenderFns } from "./AssociationScrollView.vue?vue&type=template&id=7871a1d7&scoped=true"
import script from "./AssociationScrollView.vue?vue&type=script&lang=js"
export * from "./AssociationScrollView.vue?vue&type=script&lang=js"
import style0 from "./AssociationScrollView.vue?vue&type=style&index=0&id=7871a1d7&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7871a1d7",
  null
  
)

export default component.exports