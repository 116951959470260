//数组是否空校验
export const verifyArryEmpty = (rule, value, callback) => {
  if (!value || value == null || value.length == 0) {
    callback(new Error(rule.message));
  }
  callback();
};

//数字是否大于某个数
export const verifyNumberBiggerThen = (rule, value, callback) => {
  if (value < rule.min || value === rule.min || value === "") {
    callback(new Error(rule.message));
  }
  callback();
};
